import { Box, Grid } from "@mui/material";
import { GridRowModes, useGridApiContext } from "@mui/x-data-grid";
import { EditableDatePicker, InputEditTableDropDown, InputEditTableText } from "components/UI";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import DataTable from "pages/DataTable";
import ActionBar from "pages/Partials/ActionBar";
import SubmitBar from "pages/Partials/SubmitBar";
import React, { Fragment, useMemo, useState } from "react";
import {
  useGetMaker,
  useSearchModule,
  useGetRpagkageInTable,
  useRPkgMasterRModuleDownloadExcelMutation,
  useGetMakerAll,
} from "service/rpackagemaster";
import { MessageType, ModeAction } from "state/enum";
import { apiClient, findObject, messageTypeDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import { getLocalDate } from "utils/init-config-date";
import { validationRequestErrors, validationSearchForm } from "utils/validation";

const RmoduleTable = ({
  searchForm,
  setSearchForm,
  setMsgError,
  setMsgAlert,
  setOnSearch,
  onSearch,
  mode,
  setMode,
  refetchTypeData,
  refetchMakerData,
}) => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const downloadExcel = useRPkgMasterRModuleDownloadExcelMutation();
  const [onSearchAdd, setOnSearchAdd] = useState(false);
  const getSearchCriterial = (pageNumber = 1) => {
    return {
      dataOwner: userProfile?.dataOwner, // TODO: Forced Value
      rPkgTypeId: searchForm.rPkgTypeId,
      makerPlantId: searchForm.makerPlantId,
      poNo: searchForm.poNo,
      barcodeId: searchForm.barcodeId,
      locationId: searchForm.locationId,
      firstReceiveDtFrom: searchForm.firstReceiveDtFrom,
      firstReceiveDtTo: searchForm.firstReceiveDtTo,
      // firstReceiveDtFrom: formatDateToSave(
      //   document.querySelector(".input_firstReceiveDtFrom input").value,
      //   "DD/MM/YYYY"
      // ),
      // firstReceiveDtTo: formatDateToSave(document.querySelector(".input_firstReceiveDtTo input").value, "DD/MM/YYYY"),
      pageNumber: 1,
      rowsPerPage: 10, //TODO: Get from configuration
    };
  };

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);
    await reload(value);

    setMsgError([]);
    setMsgAlert([]);
  };

  const reload = async pageNumber => {
    const searchData = await searchDataAsync({
      ...getSearchCriterial(),
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: item.rowNumber,
        ...item,
      })) ?? [];
    console.log(data);
    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
  };

  // 2. api
  // const { data: makerData } = useGetMaker({
  //   dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
  //   cd: searchForm?.rPkgCategoryId,
  // });

  const { data: makerAllData } = useGetMakerAll({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
    cd: searchForm?.rPkgCategoryId,
  });
  const { mutateAsync: searchDataAsync } = useSearchModule({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
  });

  const { data: rPackageTypeData } = useGetRpagkageInTable({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
    rPkgOwnerCompAbbr: userProfile.dataOwner, // TODO: get dataOwner from profile
    cd: searchForm?.rPkgCategoryId,
  });

  // 3. initail value search from
  //
  // const apiRef = useGridApiContext();
  // 4. columns
  const columns = useMemo(
    () => [
      {
        field: "rPkgTypeId",
        editable: true,
      },
      {
        field: "makerPlantId",
        editable: true,
      },
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        headerAlign: "center",
        align: "right",
        width: 50,
        editable: false,
        renderCell: params => {
          if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
            return <div></div>;
          }
          return params.value;
        },
      },
      {
        field: "rPkgType",
        sortable: false,
        headerName: "R-Package Type",
        headerAlign: "center",
        minWidth: 100,
        editable: mode === ModeAction.ADD ? true : false,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
        ],
        renderEditCell: params => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();
          // console.log("rPkgType params", params.value);
          if (ModeAction.VIEW === mode) {
            return findObject({
              data: rPackageTypeData,
              value: params.value,
              property: "rPkgTypeId",
              field: "rPkgType",
            });
          }
          return (
            <InputEditTableDropDown
              {...params}
              required
              setValue={value => {
                console.log(rows);
                apiRef.current.setEditCellValue({
                  id: params.id,
                  field: "rPkgTypeId",
                  value: value,
                });
              }}
              memu={rPackageTypeData?.map(val => ({
                key: val.rPkgTypeId,
                value: val.rPkgType,
              }))}
              placeholder="<Select>"
              onSearchAdd={onSearchAdd}
            />
          );
        },
        renderCell: params => {
          // console.log("====rPackageTypeData", rPackageTypeData);
          // if (ModeAction.VIEW === mode) {
          //   return findObject({
          //     data: rPackageTypeData,
          //     value: params.value,
          //     property: "rPkgTypeId",
          //     field: "rPkgType",
          //   });
          // }
          return params.value;
        },
        flex: 1
      },
      {
        field: "makerImpExpCd",
        sortable: false,
        headerName: "R-Module Maker",
        headerAlign: "center",
        type: "select",
        minWidth: mode === ModeAction.ADD ? 120 : 100,
        editable: mode === ModeAction.ADD ? true : false,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Module Maker"],
          },
        ],
        renderEditCell: params => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();
          // console.log("makerImpExpCd params", params.value);
          return (
            <InputEditTableDropDown
              {...params}
              required
              setValue={value => {
                console.log(rows);
                apiRef.current.setEditCellValue({
                  id: params.id,
                  field: "makerPlantId",
                  value: value,
                });
              }}
              memu={makerAllData?.map(val => ({
                key: val.plantId,
                value: val.impExpCd,
              }))}
              placeholder="<Select>"
              onSearchAdd={onSearchAdd}
            />
          );
        },
        renderCell: params => {
          // if (ModeAction.VIEW === mode) {
          //   return findObject({
          //     data: makerData,
          //     value: params.value,
          //     property: "plantId",
          //     field: "impExpCd",
          //   });
          // }
          return params.value;
        },
        flex: 1
      },
      {
        field: "poNo",
        sortable: false,
        headerName: "P/O Number",
        headerAlign: "center",
        minWidth: 200,
        editable: ModeAction.ADD === mode ? true : false,
        renderEditCell: params => {
          return (
            <InputEditTableText
              regularExp={/^[a-zA-Z0-9_-]*$/}
              maxLength={20}
              {...params}
              style={{ width: "220px" }}
              upperCase
            />
          );
        },
        renderCell: () => {},
        flex: 1
      },
      {
        field: "barcodeId",
        sortable: false,
        headerName: "R-Module Barcode ID",
        editable: false,
        minWidth: 150,
        headerAlign: "center",
        flex: 1
      },
      {
        field: "rPkgSts",
        sortable: false,
        headerName: "Status",
        editable: false,
        align: "left",
        headerAlign: "center",
        minWidth: 100,
        flex: 1
      },
      {
        field: "pkgSts",
        sortable: false,
        headerName: "Pack Status",
        editable: false,
        align: "left",
        headerAlign: "center",
        minWidth: 100,
        flex: 1
      },
      {
        field: "locationType",
        sortable: false,
        headerName: "Location",
        editable: false,
        align: "left",
        headerAlign: "center",
        minWidth: 180,
        flex: 1
      },
      {
        field: "usageTime",
        sortable: false,
        headerName: "Usage times",
        editable: false,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        flex: 1
      },
      {
        field: "barcodeGenDt",
        sortable: false,
        headerName: "Barcode Generated Date (DD/MM/YYYY)",
        minWidth: 150,
        editable: false,
        align: "center",
        headerAlign: "center",
        flex: 1
      },
      {
        field: "firstReceiveDt",
        sortable: false,
        headerName: "First Received Date (DD/MM/YYYY)",
        minWidth: 150,
        editable: false,
        align: "center",
        headerAlign: "center",
        flex: 1
      },
      {
        field: "expireDt",
        sortable: false,
        headerName: "Expired Date (DD/MM/YYYY) ",
        minWidth: mode === ModeAction.EDIT ? 180 : 150,
        editable: mode === ModeAction.ADD ? false : true,
        align: "center",
        headerAlign: "center",
        renderEditCell: params => {
          return <EditableDatePicker {...params} />;
        },
        // renderCell: (params) => {
        //   return formatDate(params.value, "DD/MM/YYYY");
        // },
        flex: 1
      },
    ],
    [rows]
  );

  //   // 5. columnGroupingModel
  //   const columnGroupingModel = [
  //     {
  //       groupId: "No.",
  //       headerAlign: "center",
  //       children: [{ field: "rowNumber" }],
  //       headerClassName: "align-items-end",
  //     },
  //     {
  //       groupId: "Container Type",
  //       headerAlign: "center",
  //       children: [{ field: "containerType" }],
  //       headerClassName: "align-items-end",
  //     },
  //     {
  //       groupId: "Container Ratio",
  //       headerAlign: "center",
  //       children: [
  //         { field: "crLength" },
  //         { field: "crWidth" },
  //         { field: "crHeight" },
  //       ],
  //       headerClassName: "table-header-group",
  //     },
  //     {
  //       groupId: "Total Ratio (LxWxH)",
  //       headerAlign: "center",
  //       children: [{ field: "ttrLxw" }],
  //       headerClassName: "align-items-end",
  //     },
  //     {
  //       groupId: "Width Accept to Close",
  //       headerAlign: "center",
  //       children: [{ field: "wAccept" }],
  //       headerClassName: "align-items-end",
  //     },
  //   ];
  const getSearch = async pageNumber => {
    setOnSearch(true);
    const searchData = await searchDataAsync(getSearchCriterial(pageNumber));

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: item.rowNumber,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  const handleSearch = async e => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);
      setOnSearchAdd(true)
      const { searchData, data } = await getSearch(1);

      const { isSuccess, errors } = validationSearchForm({
        data: searchForm,
        rule: [
          {
            field: "rPkgTypeId",
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
          {
            data: data,
            type: MessageType.NOT_FOUND,
          },
        ],
      });

      if (!searchForm.rPkgTypeId) {
        document.getElementById("select_rPkgTypeId").focus();
      }

      if (!isSuccess) {
        setMsgError(errors);
        setOnSearchAdd(false)
        window.scrollTo(0, 0);
        return;
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setOnSearchAdd(false)
    setSearchForm(prev => ({
      ...prev,
      rPkgTypeId: "",
      makerPlantId: "",
      poNo: "",
      barcodeId: "",
      rMdMaker: "",
      location: "",
      locationId: "",
      firstReceiveDtFrom: "",
      firstReceiveDtTo: "",
    }));

    setRows([]);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { searchData, data } = await getSearch();
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }

    try {
      //Try to search first if not data or any error should not download file
      const data = getSearchCriterial();
      // const filename = `R_Module_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;

      const { isSuccess, errors } = validationSearchForm({
        data: searchForm,
        rule: [
          {
            field: "rPkgTypeId",
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
          {
            data: data,
            type: MessageType.NOT_FOUND,
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      await downloadExcel(data);
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }

    try {
      const selectedNo = rowSelectionModel[0];
      const id = rows.find(v => v.rowNumber === selectedNo)?.rModuleId;
      const updateDt = rows.find(v => v.rowNumber === selectedNo)?.updateDt;

      await apiClient.delete(`/r-module/delete/${id}`, {
        data: {
          updateBy: userProfile.userId,
          updateDt: updateDt,
        },
      });

      await reload(pageNumber);
      refetchTypeData();
      refetchMakerData();
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      refetchTypeData();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleCopyClick = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    //    setDownloadButton(true);

    console.log(rowSelectionModel);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);
    console.log(rowCopy);
    const maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

    setRows(oldRows => [
      ...oldRows,
      {
        rowNumber: maxNo + 1,
        makerImpExpCd: rowCopy.makerPlantId, // Key of makerImpExpCd is makerPlantId [Combobox]
        makerPlantId: rowCopy.makerPlantId,
        rPkgTypeId: rPackageTypeData?.find(o => o.rPkgTypeId === rowCopy.rPkgTypeId) ? rowCopy.rPkgTypeId : "",
        rPkgType: rowCopy.rPkgTypeId, // Key of rPkgType is rPkgTypeId [Combobox]
        poNo: rowCopy.poNo,
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1]);
  };

  const processRowUpdate = async newRow => {
    setMsgError([]);
    setMsgAlert([]);

    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    if (!isSuccess) {
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }

    if (newRow.isNew) {
      try {
        await apiClient.post(`/r-module/create`, {
          dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
          cd: "TMATH",
          rPkgType: findObject({
            data: rPackageTypeData,
            value: newRow.rPkgType,
            property: "rPkgTypeId",
            field: "rPkgType",
          }),
          makerCompanyId: findObject({
            data: makerAllData,
            value: newRow.makerPlantId,
            property: "plantId",
            field: "companyId",
          }),
          // rPkgTypeId: rPackageTypeData?.find(o => o.rPkgTypeId === newRow.rPkgTypeId) ? newRow.rPkgTypeId : "",
          // makerPlantId: makerData?.find(o => o.makerPlantId === newRow.makerPlantId) ? newRow.makerPlantId : "",
          rPkgTypeId: newRow.rPkgTypeId,
          makerPlantId: newRow.makerPlantId,
          poNo: newRow.poNo,
          createBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        // await reload(1);
        // setMode(ModeAction.VIEW);
        // setRowSelectionModel([]);

        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        refetchTypeData();
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      try {
        // const exprieDtDom = document.querySelector(
        //   `[id=expireDt] > .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input`
        // ).value;
        await apiClient.patch(`/r-module/edit/${newRow.rModuleId}`, {
          exprieDt: newRow.expireDt,
          updateBy: userProfile.userId,
          updateDt: newRow.updateDt,
        });

        // await reload(pageNumber);
        // setMode(ModeAction.VIEW);
        // setRowSelectionModel([]);

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }

    if (rows.length === 1 && ModeAction.ADD === mode) {
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      await reload(pageNumber);
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
    }
    refetchMakerData();
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  return (
    <Fragment>
      <>
        <ActionBar
          mode={mode}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setMode={setMode}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          rowModesModel={rowModesModel}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleDownloadExcel={handleDownloadExcel}
          handleDeleteClick={handleDeleteClick}
          handleCopyClick={handleCopyClick}
          firstField={"select_rPkgCategoryId"}
          functionId={"WDN91020"}
        />
        <Box sx={{ padding: "1rem" }}>
          <Grid>
            {onSearch && (
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={null}
                columnVisibilityModel={{
                  rPkgTypeId: false,
                  makerPlantId: false,
                }}
              />
            )}
            <SubmitBar
              mode={mode}
              rows={rows}
              setMode={setMode}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              setMsgError={setMsgError}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              functionId={"WDN91020"}
            />
          </Grid>
        </Box>
      </>
    </Fragment>
  );
};

export default RmoduleTable;
