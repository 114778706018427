import { Box, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, PopupDialog } from "components/UI";
import { userProfile } from "constant";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  useExportInvoiceCategoryQuery,
  useExportInvoiceReportFormatQuery,
  useExportInvoiceSearchMutation,
} from "shared/services/invoice/export-invoice-creation";
import { ModeAction } from "state/enum";
import CriterialSection from "./components/CriterialSection";
import { DEFAULT_EXPORT_INVOICE_INFO, DEFAULT_STATE_WDN95011, DEFAULT_STATE_WDN95012 } from "./constants/constants";
import ButtonCriterial from "./components/ButtonCriterial";
import { FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import ButtonAction from "./components/ButtonAction";
import { useColumn } from "./hooks/useColumn";
import { useButtonMode } from "./hooks/useButtonMode";
import TableSection from "./components/TableSection";
import CreateInvoiceSubScreen from "./components/CreateInvoiceSubScreen/CreateInvoiceSubScreen";
import { transformData } from "./hooks/tranformData";
import { responseErrors } from "utils";
import CancelVanningResultSubScreen from "./components/CancelVanningResultSubScreen/CancelVanningResultSubScreen";

const ExportInvoiceCreationMaintenanceScreen = () => {
  // Form
  const [form, setForm] = useState(DEFAULT_EXPORT_INVOICE_INFO);
  const [formSubScreen, setFormSubScreen] = useState(DEFAULT_STATE_WDN95011);
  const [formCancelSubScreen, setFormCancelSubScreen] = useState(DEFAULT_STATE_WDN95012);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [onSearch, setOnSearch] = useState(false);

  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  //Button State
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const [addBtn, setAddBtn] = useState(false);
  const [copyBtn, setCopyBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);

  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);

  // API
  const { data: importerData } = useExportInvoiceCategoryQuery(userProfile.dataOwner);
  const { data: reportFormatData } = useExportInvoiceReportFormatQuery(userProfile.dataOwner);
  const searchExportInfo = useExportInvoiceSearchMutation();

  // State sub screen
  const [openPopupSub, setOpenPopupSub] = useState({
    open: false,
    isEdit: false,
    data: {},
  });
  const [openPopupCancelSub, setOpenPopupCancelSub] = useState(false);

  // columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumn({
      rows,
      mode,
      dataList: {},
    });
  }, [rows, mode]);

  useEffect(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useButtonMode({
        mode,
        stateButton: {
          setSearchBtn,
          setClearBtn,
          setAddBtn,
          setCopyBtn,
          setEditBtn,
          setDeleteBtn,
        },
      }),
    [mode]
  );

  const refreshRowsTable = async () => {
    try {
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        importerId: form?.importerId,
        vesselName: form?.vesselName,
        invoiceNo: form?.invoiceNo,
        etdFrom: form?.etdFrom,
        etdTo: form?.etdTo,
        loginUserCompanyAbbr: userProfile.dataOwner, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchExportInfo(body);

      if (!searchData?.result?.data?.length) {
        setOnSearch(false);
        setPageNumber(FIRST_PAGE);
        setMode(ModeAction.VIEW);
        setRows([]);
        setRowSelectionModel([]);
        setRowModesModel({});
        setPagination({});
        return;
      }
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);
      const data = transformData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN95010 : Export Invoice Creation Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>

      <Box sx={{ padding: "1rem" }}>
        <CriterialSection
          form={form}
          setForm={setForm}
          mode={mode}
          dataList={{
            importerData,
          }}
        />
        <ButtonCriterial
          form={form}
          setForm={setForm}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          rows={rows}
          setRows={setRows}
          // setShippingInfo={setShippingInfo}
          stateButton={{ searchBtn, clearBtn }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{
            rowModesModel,
            setRowModesModel,
            rowSelectionModel,
            setRowSelectionModel,
          }}
          pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
          functionId={"WDN95010"}
        />
      </Box>
      {/* {onSearch && ( */}
      <Box>
        <ButtonAction
          form={form}
          setForm={setForm}
          mode={mode}
          setMsg={{ setMsgAlert, setMsgError }}
          setOpenPopupSub={setOpenPopupSub}
          setOpenPopupCancelSub={setOpenPopupCancelSub}
          dataList={{
            reportFormatData,
          }}
          rows={rows}
          rowSelection={{
            rowModesModel,
            setRowModesModel,
            rowSelectionModel,
            setRowSelectionModel,
          }}
          onSearch={onSearch}
          refreshRowsTable={refreshRowsTable}
          functionId={"WDN95010"}
        />
      </Box>
      {/* )} */}
      {onSearch && (
        <Box sx={{ pr: "1rem", pl: "1rem" }}>
          <TableSection
            mode={mode}
            setMode={setMode}
            rows={rows}
            setRows={setRows}
            columns={columns}
            form={form}
            // formRPkg={formRPkg}
            // formContainer={formContainer}
            refreshRowsTable={refreshRowsTable}
            setOnSearch={setOnSearch}
            setMsg={{ setMsgAlert, setMsgError }}
            rowSelection={{
              rowModesModel,
              setRowModesModel,
              rowSelectionModel,
              setRowSelectionModel,
            }}
            pagination={{
              pagination,
              setPagination,
              pageNumber,
              setPageNumber,
            }}
            dataList={
              {
                //   importerList,
                //   consigneeList,
                //   brokerList,
              }
            }
          />
        </Box>
      )}
      <PopupDialog
        id="container"
        maxWidth="xl"
        onFooter={true}
        onOpen={openPopupSub.open}
        content={
          <CreateInvoiceSubScreen
            alertMain={{ setMsgAlert, setMsgError }}
            onOpen={openPopupSub}
            setOnOpen={setOpenPopupSub}
            // modeOfWDN95011={mode}
            // setModeOfWDN95011={setMode}
            form={formSubScreen}
            setForm={setFormSubScreen}
          />
        }
      />
      <PopupDialog
        id="container"
        maxWidth="xl"
        onFooter={true}
        onOpen={openPopupCancelSub}
        content={
          <CancelVanningResultSubScreen
            onOpen={openPopupCancelSub}
            setOnOpen={setOpenPopupCancelSub}
            // modeOfWDN95012={mode}
            // setModeOfWDN95012={setMode}
            form={formCancelSubScreen}
            setForm={setFormCancelSubScreen}
          />
        }
      />
    </Fragment>
  );
};

export default ExportInvoiceCreationMaintenanceScreen;
