import React, { Fragment, useEffect, useState } from "react";
//Component
import { HeaderContentBar } from "components/Layout";
import { Box, Stack, Link, Divider } from "@mui/material";
import { AlertMessage, PopupDialog } from "components/UI";
import RPackageSubScreen from "pages/Invoice/ShippingInformationMaintenanceScreen/components/R-PackageSubScreen/RPackageSubScreen";
import DetailSection from "pages/Invoice/ShippingInformationMaintenanceScreen/components/ContainerSubScreen/components/DetailSection";
import ButtonContainer from "pages/Invoice/ShippingInformationMaintenanceScreen/components/ContainerSubScreen/components/ButtonContainer";
import TableContainer from "pages/Invoice/ShippingInformationMaintenanceScreen/components/ContainerSubScreen/components/TableContainer";
//util
import {
  transformContdUpdateTemp,
  transformRPKgUpdateTemp,
  transformDataContainer,
  transformDataRPkg,
} from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/transformData";
import { isEmpty } from "lodash";
import { messageDisplay, responseErrors } from "utils";
import SumQtyRPackage from "../../utils/sum-qty-r-package";
// Hook
import { useLoadingContext } from "shared/contexts/LoadingContext";
import useColContainer from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/useColContainer";
import { useConfirmDialogContext } from "context/confirmDialogContext";

// Service
import {
  useRPackageSearchMutation,
  useShippingInfoOrderTypeQuery,
  useShippingUpdateTempContainerMutation,
  useShippingUpdateTempRPkgMutation,
} from "shared/services/invoice";
// Type
import { COMMON_ENUM, FIRST_PAGE, PAGINATION } from "shared/constants";
import { MSG_TYPE, ModeAction } from "state/enum";
import { userProfile } from "constant";
import ConfirmMatching from "./components/ConfirmMatching";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";

export default function ContainerSubScreen({
  modeOfWDN95030,
  onOpen,
  setOnOpen,
  form,
  setForm,
  searchCont,
  setSearchCont,
}) {
  const { startLoading, stopLoading } = useLoadingContext();
  const ROW_PER_PAGE_TWENTY = 20; // default
  const confirmDialogCtx = useConfirmDialogContext();
  const sumRPkg = new SumQtyRPackage();
  const [dataRPkg, setDataRPkg] = useState({});
  const [rPkgQty, setRPkgQty] = useState({});
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);
  // Table
  const [rows, setRows] = useState([]);
  // const [rowsTemp, setRowsTemp] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);
  // Api

  const searchRPackage = useRPackageSearchMutation();
  const updateTempContTable = useShippingUpdateTempContainerMutation();
  const updateTempTable = useShippingUpdateTempRPkgMutation();
  const { data: orderTypeList } = useShippingInfoOrderTypeQuery();

  // State r-package sub screen
  const [openPopupRPackageSub, setOpenPopupRPackageSub] = useState(false);
  const handleOpenRPackageSubScreen = async (row, containerType) => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      // If add mode : User need to fill container no.
      const containerNo = containerType === "P" ? row?.planContainerNo : row?.actualContainerNo;
      if (isEmpty(containerNo)) {
        const message = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0031AERR", msg: ["Container No."] });
        setMsgError([message]);
        return;
      }
      if (row?.isNew) {
        let rowsTemp = [];
        rowsTemp = rows?.map(val => {
          // let temp = { ...val };
          if (val?.rowNumber === row?.rowNumber) {
            val.planContainerNo = row?.planContainerNo;
            return val;
          }
          return val;
        });
        setRows(rowsTemp);
      }

      let sessionId = row?.contSessionId;
      const body = {
        shippingInfoDContId: row?.shippingInfoDContId,
        vanningInfoHId: row?.vanningInfoHId, // optional
        containerNo: containerNo,
        containerType: containerType, // P = Plan, A = Actual
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE_TWENTY,
        sesionnId: containerType === "P" ? sessionId : "",
      };
      startLoading();
      const searchData = await searchRPackage(body);
      stopLoading();

      setForm(prev => ({
        ...prev,
        containerNo: containerNo,
        containerType: containerType,
        dataOwner: userProfile.dataOwner,
        vanInfoHdrID: row?.vanningInfoHId,
        shippingInfoDContId: row?.shippingInfoDContId,
        [API_INVOICE_SHIPPING_INFO.CONT_SESSION_ID]: sessionId,
      }));

      setDataRPkg(searchData);
      setOpenPopupRPackageSub(true);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const columns = React.useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColContainer({
      rows,
      mode,
      handleOpenRPackageSubScreen,
      functionId: "WDN95031",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, mode, searchCont]);

  const handleClose = async () => {
    const confirm = await confirmDialogCtx.success({ type: "closeScreen" });
    if (!confirm) {
      return;
    }
    setOnOpen(false);
  };
  const updateTempContainer = async (contdList, shippingSessionId) => {
    try {
      //NOTE sessionId of shipping
      const contdUpdate = transformContdUpdateTemp(contdList, shippingSessionId);
      const result = await updateTempContTable(contdUpdate);
      return result?.sesionnId;
    } catch (error) {
      console.log(error);
    }
  };
  const updateTempRPkg = async (rPkgList, sessionId) => {
    try {
      const contdUpdate = transformRPKgUpdateTemp(rPkgList, sessionId);
      const result = await updateTempTable(contdUpdate);
      // console.log("R-Package sessionId: ", result?.sesionnId);
      return result?.sesionnId;
    } catch (error) {
      console.log(error);
    }
  };
  const copyRPackageFunction = async row => {
    try {
      console.log("row copyFunction", row);
      row["planContainerNo"] = row?.actualContainerNo;
      row["planRModuleQty"] = row?.actualRModuleQty;
      row["planRBoxDunQty"] = row?.actualRBoxDunQty;

      const body = {
        shippingInfoDContId: row?.shippingInfoDContId,
        vanningInfoHId: row?.vanningInfoHId, // optional
        containerNo: row?.actualContainerNo,
        containerType: "A", // P = Plan, A = Actual
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE_TWENTY,
        sesionnId: row?.contSessionId,
      };

      const searchData = await searchRPackage(body);
      const formData = await transformDataRPkg(searchData?.result?.data);
      await updateTempRPkg(formData, row?.contSessionId);
      return;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearch = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      setMode(ModeAction.VIEW);
      setOnSearch(false);

      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});

      const searchData = searchCont;
      if (!searchData?.result?.data?.length) {
        setForm(prev => ({ ...prev, rows: [] }));
        setRows([]);
        setOnSearch(false);
        return;
      }

      const data = transformDataContainer(searchData?.result?.data, orderTypeList);
      setForm(prev => ({ ...prev, rows: [...data] }));
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);

      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  useEffect(() => {
    try {
      handleSearch();
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
    return;
  }, [1]);

  return (
    <Fragment>
      <Stack>
        <Stack>
          <Link href="#" color="#000" onClick={handleClose} size="small" sx={{ fontSize: "12px" }}>
            Close
          </Link>
        </Stack>
        <Stack mb={2}>
          {/* <Stack sx={{ padding: "1em" }}> */}
          <HeaderContentBar title="WDN95031 : Shipping Information Maintenance - Container Sub Screen" />
          <AlertMessage type={"warning"} message={msgError} />
          <AlertMessage type={"success"} message={msgAlert} />
        </Stack>
        <Stack sx={{ pl: "1rem", pr: "1rem" }}>
          <Box>
            <DetailSection onRPkgScreen={false} form={form} />
          </Box>
          <Box width="100%" sx={{ mb: 2 }}>
            <Divider />
          </Box>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ButtonContainer
              mode={mode}
              setMode={setMode}
              modeOfWDN95030={modeOfWDN95030}
              setOnSearch={setOnSearch}
              rows={rows}
              setRows={setRows}
              columns={columns}
              form={form}
              setForm={setForm}
              getSumQty={sumRPkg.sumActualQty}
              loading={{ startLoading, stopLoading }}
              updateTempContainer={updateTempContainer}
              setMsg={{ setMsgAlert, setMsgError }}
              pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
              rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
              functionId={"WDN95031"}
            />
          </Box>
          <Box sx={{ height: `${onSearch ? 0 : "300px"}` }}>
            {onSearch && (
              <>
                <Box>
                  <TableContainer
                    mode={mode}
                    setMode={setMode}
                    rows={rows}
                    setRows={setRows}
                    columns={columns}
                    form={form}
                    setForm={setForm}
                    getSumQty={sumRPkg.sumActualQty}
                    sumQtyRPkg={sumRPkg}
                    rPkgQty={rPkgQty}
                    setRPkgQty={setRPkgQty}
                    setOnSearch={setOnSearch}
                    loading={{ startLoading, stopLoading }}
                    updateTempContainer={updateTempContainer}
                    updateTempRPkg={updateTempRPkg}
                    setMsg={{ setMsgAlert, setMsgError }}
                    rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
                    pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
                    orderTypeList={orderTypeList}
                    functionId={"WDN95031"}
                  />
                </Box>
                <ConfirmMatching
                  form={form}
                  setForm={setForm}
                  rows={rows}
                  setRows={setRows}
                  rPkgQty={rPkgQty}
                  setRPkgQty={setRPkgQty}
                  getSumQty={sumRPkg.sumActualQty}
                  mode={mode}
                  modeOfWDN95030={modeOfWDN95030}
                  setOnOpen={setOnOpen}
                  loading={{ startLoading, stopLoading }}
                  updateTempContainer={updateTempContainer}
                  copyRPackageFunction={copyRPackageFunction}
                  pageNumber={pageNumber}
                  setMsg={{ setMsgAlert, setMsgError }}
                  rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
                  functionId={"WDN95031"}
                />
              </>
            )}
          </Box>
        </Stack>

        <PopupDialog
          id="rPkg"
          maxWidth="lg"
          onFooter={true}
          onOpen={openPopupRPackageSub}
          content={
            <RPackageSubScreen
              onOpen={openPopupRPackageSub}
              setOnOpen={setOpenPopupRPackageSub}
              form={form}
              setForm={setForm}
              modeOfWDN95031={mode}
              dataRPkg={dataRPkg}
              setDataRPkg={setDataRPkg}
              rowContainer={rows}
              setRowContainer={setRows}
              updateTempContainer={updateTempContainer}
              setSearchCont={setSearchCont}
            />
          }
        />
      </Stack>
    </Fragment>
  );
}
