import React, { Fragment, useEffect, useMemo, useState } from "react";
//Components
import { Box } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, PopupDialog } from "components/UI";
import CriterialSection from "./components/CriterialSection";
import ButtonCriterial from "./components/ButtonCriterial";
import ButtonTable from "./components/ButtonTable";
import TableSection from "./components/TableSection";
import ContainerSubScreen from "./components/ContainerSubScreen/ContainerSubScreen";
//Hooks
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useButtonMode } from "./hooks/useButtonMode";
import { useColumn } from "./hooks/useColumn";
import { transformShippingData } from "./hooks/transformData";
// Util
import { validationSearchForm } from "utils/validation";
import { findObject, responseErrors } from "utils";
// Service
import {
  useShippingInfoExporterQuery,
  useShippingInfoImporterQuery,
  useShippingInfoOrderTypeQuery,
  useShippingInfoCarFamilyCodeQuery,
  useShippingInfoLoadingPortQuery,
  useShippingInfoDischargingPortQuery,
  useShippingInfoConsigneeQuery,
  useShippingInfoBrokerQuery,
  useShippingInfoSearchMutation,
  useContainerSearchMutation,
} from "shared/services/invoice";
// Constants
import { FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { MessageType, ModeAction } from "state/enum";
import { DEFAULT_SHIPPING_INFO, DEFAULT_STATE_WDN95031 } from "./constants/constant";
// !Mock
import { userProfile } from "constant";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";
import dayjs from "dayjs";

export default function ShippingInformationMaintenanceScreen() {
  const ROW_PER_PAGE_TWENTY = 20;
  const { startLoading, stopLoading } = useLoadingContext();

  // container
  const [formContainer, setFormContainer] = useState(DEFAULT_STATE_WDN95031);
  const [searchCont, setSearchCont] = useState({});
  // Form
  const [form, setForm] = useState(DEFAULT_SHIPPING_INFO);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);

  //Button State
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const [addBtn, setAddBtn] = useState(false);
  const [copyBtn, setCopyBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);

  const [valueFieldTable, setValueFieldTable] = useState({ impCompanyAbbrId: "", exporterCd: "" });
  // API
  const searchShippingInfo = useShippingInfoSearchMutation();
  const searchContainer = useContainerSearchMutation();

  const { data: exporterList } = useShippingInfoExporterQuery(userProfile?.dataOwner);
  const { data: importerList } = useShippingInfoImporterQuery();
  const { data: orderTypeList } = useShippingInfoOrderTypeQuery();
  const { data: carFamilyCodeList } = useShippingInfoCarFamilyCodeQuery();

  const { data: loadingPortList } = useShippingInfoLoadingPortQuery(form?.exporterCd);
  const { data: dischargingPortList } = useShippingInfoDischargingPortQuery(form?.importerId);
  const { data: loadingPortListTable } = useShippingInfoLoadingPortQuery(valueFieldTable?.exporterCd);
  const { data: dischargingPortListTable } = useShippingInfoDischargingPortQuery(valueFieldTable?.impCompanyAbbrId);
  const { data: consigneeList } = useShippingInfoConsigneeQuery();
  const { data: brokerList } = useShippingInfoBrokerQuery();

  // State container sub screen
  const [openPopupContainerSub, setOpenPopupContainerSub] = useState(false);
  const refreshRowsTable = async () => {
    try {
      const body = {
        expCd: form?.exporterCd,
        impCompanyId: form?.importerId,
        loadingPort: form?.loadingPort,
        dischargingPort: form?.dischargingPort,
        etd: !form?.etd ? "" : dayjs(form?.etd, "DD/MM/YYYY").format("YYYY-MM-DD"),
        orderType: form?.orderTypeId,
        vessel: form?.vesselName,
        carfamilyCode: form?.carFamilyCode,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchShippingInfo(body);
      setFormContainer(DEFAULT_STATE_WDN95031);
      // setFormContainer(prev => ({
      //   ...prev,
      //   [API_INVOICE_SHIPPING_INFO.SHIPPING_SESSION_ID]: "",
      //   [API_INVOICE_SHIPPING_INFO.CONSIGNEE_ID]: "",
      // }));
      if (!searchData?.result?.data?.length) {
        setOnSearch(false);
        setPageNumber(FIRST_PAGE);
        setMode(ModeAction.VIEW);
        setRows([]);
        setRowSelectionModel([]);
        setRowModesModel({});
        setPagination({});
        return;
      }
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);
      const data = transformShippingData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleOpenContainerSubScreen = async row => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      let shippingSessionId = row?.shippingSessionId;
      const body = {
        shippingInfoHID: row?.shippingInfoHId,
        expCd: row?.exporterCode,
        impCompanyAbbr: row.impCompanyAbbr,
        etd: row?.etd, // DD/MM/YYYY
        impCd: row?.importerCode, // optional
        expCdSearch: "", // optional
        sesionnId: shippingSessionId, // optional
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE_TWENTY,
      };

      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "expCd",
            type: MessageType.EMPTY,
            key: ["Exporter Code"],
          },
          {
            field: "etd",
            type: MessageType.DATE_FORMAT_50,
            key: ["ETD", "DD/MM/YYYY"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      startLoading();
      const searchData = await searchContainer(body);
      stopLoading();
      // set sessionId to shipping in table
      shippingSessionId = shippingSessionId ? shippingSessionId : searchData?.result?.sessionId;
      for (const index in rows) {
        const record = rows[index];
        if (record?.rowNumber === row?.rowNumber) {
          record[API_INVOICE_SHIPPING_INFO.SHIPPING_SESSION_ID] = shippingSessionId;
        }
      }
      // row[API_INVOICE_SHIPPING_INFO.SHIPPING_SESSION_ID] = shippingSessionId;
      // set sessionId to state container in table
      setFormContainer(prev => ({
        ...prev,
        exporterComp: !row.importerCode
          ? ""
          : findObject({ data: exporterList, value: row.importerCode, property: "impExpCd", field: "companyId" }),
        importerComp: !row.impCompanyAbbr ? "" : row.impCompanyAbbr,
        etdDt: !row.etd ? "" : row.etd,
        consignee: !row.consignee ? "" : row.consignee,
        loadingPort: !row.loadingPort ? "" : row.loadingPort,
        dischargingPort: !row.dischargingPort ? "" : row.dischargingPort,
        broker: !row.broker ? "" : row.broker,
        vesselName: !row.vesselName ? "" : row.vesselName,
        //search
        exporterCd: !row?.exporterCode ? "" : row?.exporterCode,
        importerCd: !row?.importerCode ? "" : row?.importerCode,
        importerId: !row?.impCompanyAbbrId
          ? ""
          : findObject({ data: exporterList, value: row.importerComp, property: "companyAbbr", field: "companyId" }),
        shippingHdrID: row?.shippingInfoHId,
        [API_INVOICE_SHIPPING_INFO.SHIPPING_SESSION_ID]: shippingSessionId,
        subScreenViewed: "Y",
      }));
      // set flag open sub screen container >> impact when update
      // row["subScreenViewed"] = "Y";
      console.log(" handleOpenContainerSubScreen >>", row);

      setSearchCont(searchData);
      setOpenPopupContainerSub(true);
      return;
    } catch (error) {
      stopLoading();
      console.error(error);
      return;
    }
  };

  const handleValueFieldTable = (value, field) => {
    try {
      if (field === "exporterCode") {
        setValueFieldTable(prev => ({ ...prev, exporterCd: value }));
        return;
      } else {
        setValueFieldTable(prev => ({ ...prev, impCompanyAbbrId: value }));
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumn({
      rows,
      mode,
      setValueFieldTable,
      handleValueFieldTable,
      dataList: {
        exporterList,
        importerList,
        loadingPortList: loadingPortListTable,
        dischargingPortList: dischargingPortListTable,
        consigneeList,
        brokerList,
      },
      handleOpenContainerSubScreen,
      functionId: "WDN95030",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, mode, valueFieldTable, dischargingPortListTable, loadingPortListTable]);
  useEffect(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useButtonMode({
        mode,
        stateButton: { setSearchBtn, setClearBtn, setAddBtn, setCopyBtn, setEditBtn, setDeleteBtn },
      }),
    [mode]
  );

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN95030 :Shipping Information Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "1rem" }}>
        <CriterialSection
          form={form}
          setForm={setForm}
          mode={mode}
          dataList={{
            exporterList,
            importerList,
            loadingPortList,
            dischargingPortList,
            orderTypeList,
            carFamilyCodeList,
          }}
        />
        <ButtonCriterial
          form={form}
          setForm={setForm}
          setFormContainer={setFormContainer}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          rows={rows}
          setRows={setRows}
          loading={{ startLoading, stopLoading }}
          // setShippingInfo={setShippingInfo}
          stateButton={{ searchBtn, clearBtn }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
          pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
          functionId={"WDN95030"}
        />
      </Box>
      <Box sx={{ pr: "1rem", pl: "1rem" }}>
        <ButtonTable
          exporterList={exporterList}
          mode={mode}
          setMode={setMode}
          rows={rows}
          setRows={setRows}
          columns={columns}
          form={form}
          setOnSearch={setOnSearch}
          refreshRowsTable={refreshRowsTable}
          setValueFieldTable={setValueFieldTable}
          stateButton={{ addBtn, copyBtn, editBtn, deleteBtn }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
          pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
          functionId={"WDN95030"}
        />
      </Box>
      {onSearch && (
        <Box sx={{ pr: "1rem", pl: "1rem" }}>
          <TableSection
            mode={mode}
            setMode={setMode}
            rows={rows}
            setRows={setRows}
            columns={columns}
            form={form}
            formContainer={formContainer}
            refreshRowsTable={refreshRowsTable}
            setOnSearch={setOnSearch}
            setMsg={{ setMsgAlert, setMsgError }}
            loading={{ startLoading, stopLoading }}
            rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
            pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
            dataList={{
              exporterList,
              importerList,
              consigneeList,
              brokerList,
            }}
            functionId={"WDN95030"}
          />
        </Box>
      )}
      <PopupDialog
        id="container"
        maxWidth="xl"
        onFooter={true}
        onOpen={openPopupContainerSub}
        content={
          <ContainerSubScreen
            onOpen={openPopupContainerSub}
            setOnOpen={setOpenPopupContainerSub}
            modeOfWDN95030={mode}
            form={formContainer}
            setForm={setFormContainer}
            searchCont={searchCont}
            setSearchCont={setSearchCont}
          />
        }
      />
    </Fragment>
  );
}
