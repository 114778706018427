import { userProfile } from "constant";
import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
import { waitAndDownload } from "../common-dowload/download-helper-service";
const BaseServicePath = process.env.REACT_APP_API_EXPORT_INVOICE;
const BaseServiceMaster = process.env.REACT_APP_API_PREFIX;

const ExportInvoiceCreationApiService = () => ({
  useExportInvoiceCategoryQuery: (dataOwner, cd = "I", companyId = "all") => {
    const data = useCustomQuery({
      key: "export-invoice-category",
      url: `${BaseServicePath}/category/export-invoice/company/${cd}/${dataOwner}/${companyId}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceReportFormatQuery: dataOwner => {
    const data = useCustomQuery({
      key: "export-invoice-report-format",
      url: `${BaseServiceMaster}/category/${dataOwner}/REPORT_FORMAT/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceOrderTypeQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-order-type",
      url: `${BaseServiceMaster}/category/COMMON/ORDER_TYPE/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceImpCompanyQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-imp-company",
      url: `${BaseServicePath}/category/export-invoice/importer`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceExpCompanyQuery: (dataOwner, expCompanyAbbr = "TMT", userLocation) => {
    const data = useCustomQuery({
      key: "export-invoice-exp-company",
      url: `${BaseServicePath}/category/export-invoice/exporter/${dataOwner}/${expCompanyAbbr}/${userLocation}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceCategoryVanningPlantQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-category",
      url: `${BaseServicePath}/category/export-invoice/vanning/plant`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceCategoryVanningPlantMutation: (
    dataOwner = "TDEM",
    etdDt = "06/07/2023",
    impCompanyId = "CMP_2111_000001",
    expCd = "722E"
  ) => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (
      body = {
        dataOwner,
        etdDt,
        impCompanyId,
        expCd,
      }
    ) => {
      return await mutate({
        url: `${BaseServicePath}/category/export-invoice/vanning/plant`,
        data: body,
        method: "POST",
      });
    };
  },
  useExportInvoiceCategoryBrokerQuery: (cd = "B", dataOwner = "TDEM", companyId = "CMP_2111_000001") => {
    const data = useCustomQuery({
      key: "export-invoice-category",
      url: `${BaseServicePath}/category/export-invoice/company/${cd}/${dataOwner}/${companyId}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceCategoryCurrencyQuery: (category = "TDEM") => {
    const data = useCustomQuery({
      key: "export-invoice-category",
      url: `${BaseServiceMaster}/category/${category}/CURRENCY_CD/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },

  useExportInvoiceShipmentEtdQuery: (dataOwner, impCompanyId, expCd, loginUserCompanyAbbr) => {
    const data = useCustomQuery({
      key: "export-invoice-shipment-etd",
      url: `${BaseServicePath}/category/export-invoice/shipment/etd`,
      body: {
        dataOwner,
        impCompanyId,
        expCd,
        loginUserCompanyAbbr,
      },
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });

    return data;
  },
  useExportInvoiceEmUserQuery: (companyAbbr = "TMATH") => {
    // return (companyAbbr = "TMATH") => {
    //   const data = useCustomQuery({
    //     key: "export-invoice-em-user",
    //     url: `${BaseServicePath}/category/export-invoice/em-user/${companyAbbr}`,
    //     method: "GET",
    //     enabled: true,
    //     select: (data) => data.result,
    //     keepPreviousData: true,
    //     invalidateKeys: [companyAbbr],
    //   });
    // };

    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (
      body = {
        companyAbbr,
      }
    ) => {
      return await mutate({
        url: `${BaseServicePath}/category/export-invoice/em-user/${body.companyAbbr}`,
        data: [],
        method: "GET",
      });
    };

    // return data;
  },
  useExportInvoicePackingMonthQuery: (
    dataOwner = "TDEM",
    etdDt = "06/07/2023",
    impCompanyId = "CMP_2111_000001",
    expCd = "722E"
  ) => {
    const data = useCustomQuery({
      key: "export-invoice-packing-month",
      url: `${BaseServicePath}/category/export-invoice/packing/month`,
      body: {
        dataOwner,
        etdDt,
        impCompanyId,
        expCd,
      },
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });

    return data;
  },
  useExportInvoicePackingMonthMutation: (
    dataOwner = "TDEM",
    etdDt = "06/07/2023",
    impCompanyId = "CMP_2111_000001",
    expCd = "722E"
  ) => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (
      body = {
        dataOwner,
        etdDt,
        impCompanyId,
        expCd,
      }
    ) => {
      return await mutate({
        url: `${BaseServicePath}/category/export-invoice/packing/month`,
        data: body,
        method: "POST",
      });
    };
  },
  useExportInvoiceShipmentEtdMutation: (dataOwner, impCompanyId, expCd, loginUserCompanyAbbr) => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (
      body = {
        dataOwner,
        impCompanyId,
        expCd,
        loginUserCompanyAbbr,
      }
    ) => {
      return await mutate({
        url: `${BaseServicePath}/category/export-invoice/shipment/etd`,
        data: body,
        method: "POST",
      });
    };
  },
  useExportInvoiceShipmentCarrierMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/category/export-invoice/shipment/carrier`,
        data: body,
        method: "POST",
      });
    };
  },
  useExportInvoiceSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useExportInvoiceDownloadExcelMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/export-invoice-report/generatereport`,
          method: "POST",
          data: body,
        },
        {
          onSuccess: response => {
            waitAndDownload(60, response);
          },
        }
      );
    };
  },
  useExportInvoiceSearchSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useExportInvoiceCreateDetailVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useExportInvoiceEditDetailVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, invoiceId = "EXDCR2324") => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/edit/${invoiceId}`,
        method: "POST",
        data: body,
      });
    };
  },
  useExportInvoiceSearchCancelVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/cancel-vanning/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useExportInvoiceCancelCancelVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/cancel-vanning/cancel`,
        method: "POST",
        data: body,
      });
    };
  },
  //DELETE Invoice DELETE
  useExportInvoiceCancelInvoiceMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (
      invoiceId,
      deleteData = {
        dataOwner: userProfile.dataOwner,
        loginUserCompanyAbbr: userProfile.comAbbrApmc,
        userId: userProfile.userId,
      }
    ) => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/cancel/${invoiceId}`,
        data: deleteData,
        method: "DELETE",
      });
    };
  },
});

export const {
  //get
  useExportInvoiceCategoryQuery,
  useExportInvoiceReportFormatQuery,
  useExportInvoiceOrderTypeQuery,
  useExportInvoiceImpCompanyQuery,
  useExportInvoiceExpCompanyQuery,
  useExportInvoicePackingMonthQuery,
  useExportInvoiceCategoryVanningPlantQuery,
  useExportInvoiceCategoryBrokerQuery,
  useExportInvoiceCategoryCurrencyQuery,
  useExportInvoiceEmUserQuery,
  // post
  useExportInvoiceShipmentEtdQuery,
  useExportInvoiceSearchMutation,
  useExportInvoiceDownloadExcelMutation,
  useExportInvoiceSearchSubScreenMutation,
  useExportInvoiceSearchCancelVanningResultSubScreenMutation,
  useExportInvoiceCancelCancelVanningResultSubScreenMutation,
  useExportInvoiceEditDetailVanningResultSubScreenMutation,
  useExportInvoicePackingMonthMutation,
  useExportInvoiceCategoryVanningPlantMutation,
  useExportInvoiceCreateDetailVanningResultSubScreenMutation,
  useExportInvoiceShipmentCarrierMutation,
  useExportInvoiceShipmentEtdMutation,
  // delete
  useExportInvoiceCancelInvoiceMutation,
} = ExportInvoiceCreationApiService();
