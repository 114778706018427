import React, { useState, useEffect } from "react";
//Component
import { InputButton } from "components/UI";
import { GridRowModes } from "@mui/x-data-grid";
// Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { useButtonModeContainer } from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/useButtonModeContainer";

//Type
import { BUTTON_VALUE, COMMON_ENUM } from "shared/constants";
import { MSG_TYPE, ModeAction } from "state/enum";
import { isBlankOrNull, messageDisplay, responseErrors } from "utils";
import sleep from "shared/hooks/Sleep";
const ONE_RECORD = 1;

export default function ButtonContainer({
  mode,
  setMode,
  modeOfWDN95030,
  setOnSearch,
  rows,
  setRows,
  columns,
  form,
  setForm,
  getSumQty,
  updateTempContainer,
  loading: { startLoading, stopLoading },
  setMsg: { setMsgAlert, setMsgError },
  pagination: { pagination, setPagination, pageNumber, setPageNumber },
  rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
  functionId,
}) {
  const confirmDialogCtx = useConfirmDialogContext();
  //Button State
  const [addBtn, setAddBtn] = useState(false);
  const [copyBtn, setCopyBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const handleCopyActualData = () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      let msg;
      let data = [];
      let hasShippingPlan = false;

      // check has selected over 1 row
      if (rowSelectionModel?.length >= 2) {
        rowSelectionModel.forEach(selected => {
          let temp = rows.find(row => row?.rowNumber === selected);
          console.log(rows);

          // check row selected has shipping plan
          if (isBlankOrNull(temp?.planContainerNo)) {
            data.push(temp);
            return;
          }
          hasShippingPlan = true;
          return hasShippingPlan;
        });

        // b) In case of some selected records already have Shipping Plan
        if (hasShippingPlan) {
          msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MDN93008AERR", msg: [""] });
          setMsgError([msg]);
          return;
        } else {
          // change mode of row selected
          let tempRowModesModel = rowModesModel;
          const rowSelect = rowSelectionModel.map(val => {
            const row = rows.find(v => v.rowNumber === val);
            row["planContainerNo"] = row?.actualContainerNo;
            row["planRModuleQty"] = row?.actualRModuleQty;
            row["planRBoxDunQty"] = row?.actualRBoxDunQty;
            row.status = "Y";

            return (tempRowModesModel = {
              ...tempRowModesModel,
              [val]: { mode: GridRowModes.Edit },
            });
          });
          if (rowSelect) {
            setRowModesModel(tempRowModesModel);
          }
          setMode(ModeAction.COPY);
          return;
        }
      } else {
        // c) In case of at least one Actual Vanning Data without Shipping Plan is selected ,screen layout similar to Add Mode
        const selectedNo = rowSelectionModel[0];
        const row = rows.find(v => v.rowNumber === selectedNo);

        if (!isBlankOrNull(row?.planContainerNo)) {
          msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MDN93008AERR", msg: [""] });
          setMsgError([msg]);
          return;
        }
        row["planContainerNo"] = row?.actualContainerNo;
        row["planRModuleQty"] = row?.actualRModuleQty;
        row["planRBoxDunQty"] = row?.actualRBoxDunQty;
        row.status = "Y";
        // change mode of row selected
        let tempRowModesModel = rowModesModel;
        const rowSelect = rowSelectionModel.map(val => {
          return (tempRowModesModel = {
            ...tempRowModesModel,
            [val]: { mode: GridRowModes.Edit },
          });
        });
        if (rowSelect) {
          setRowModesModel(tempRowModesModel);
        }
        setMode(ModeAction.COPY);
      }
      return;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleAdd = () => {
    try {
      let maxNo = -1;
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.ADD);
      setOnSearch(true);
      const field = {};
      columns.forEach(column => {
        if (column.field === "rowNumber") {
          maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
          field[column.field] = rows.length ? maxNo + 1 : maxNo;
        } else {
          field[column.field] = "";
        }
      });

      setRows(prev => [
        ...prev,
        {
          ...field,
          status: "N",
          planRPkgDetail: "P",
          actualRPkgDetail: "A",
          isNew: true,
        },
      ]);

      const maxNoModel = rows.length ? maxNo + 1 : maxNo;

      setRowModesModel(oldModel => ({
        ...oldModel,
        [maxNoModel]: { mode: GridRowModes.Edit },
      }));

      setRowSelectionModel([maxNoModel]);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const handleEdit = () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      let msg;
      if (rowSelectionModel.length !== ONE_RECORD) {
        msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD1017AERR", msg: [""] });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(ele => ele.rowNumber === selectedNo);
      if (!row?.planContainerNo) {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN95031AERR",
          msg: ["Shipping Plan", "Delete operation."],
        });
        setMsgError([msg]);
        setRowSelectionModel([]);
        setMode(ModeAction.VIEW);
        return;
      }

      setMode(ModeAction.EDIT);
      let tempRowModesModel = rowModesModel;
      const rowSelect = rowSelectionModel.map(val => {
        return (tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.Edit },
        });
      });
      if (rowSelect) {
        setRowModesModel(tempRowModesModel);
      }
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const handleDelete = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      let msg;

      if (rowSelectionModel?.length !== ONE_RECORD) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD1016AERR", msg: [""] });
        setMsgError([msg]);
        return;
      }
      const confirm = await confirmDialogCtx.success({
        type: "SHIPPING_INFO_CONFIRM_DELETE",
      });
      if (!confirm) {
        return;
      }

      let dataExists = true;
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(ele => ele.rowNumber === selectedNo);

      //* In case row that user selected actual container No is empty
      if (!row?.actualContainerNo) {
        // delete row selected  in rows variable
        const newRows = rows.filter(row => !rowSelectionModel.includes(row.rowNumber));
        // delete row selected  in form variable
        const newRowForm = form?.rows.filter(row => !rowSelectionModel.includes(row.rowNumber));
        setForm(prev => ({ ...prev, rows: newRowForm }));
        if (!newRows?.length) {
          setRows([]);
          // setOnSearch(false);
        } else {
          setRows(newRows);
        }
        startLoading();
        await updateTempContainer(newRows?.length <= 0 ? [] : newRowForm, form?.shippingSessionId);
        stopLoading();
        msg = messageDisplay({
          type: MSG_TYPE.INFO,
          code: "MDN90044AINF",
          msg: [],
        });
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        setRowModesModel({});
        setRowSelectionModel([]);
        setMode(ModeAction.VIEW);
        return true;
      } else if (!row?.planContainerNo) {
        dataExists = false;
      } else {
        row.planContainerNo = "";
        row.planRModuleQty = "";
        row.planRBoxDunQty = "";
      }

      // check sum qty
      const { sumRMQty, sumOTQty } = await getSumQty(row);
      sleep(3000);
      let notEqual = false;
      if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
      if (parseInt(row.planRModuleQty) !== sumRMQty) notEqual = true;
      if (parseInt(row.planRBoxDunQty) !== sumOTQty) notEqual = true;

      // check vanning status
      row.status = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;

      // update row selected in rows variable
      let tempUpdateRow = [...rows];
      rows.forEach((item, index) => {
        if (item.rowNumber === row.rowNumber) {
          tempUpdateRow[index] = { ...tempUpdateRow[index], ...row };
          setRows(tempUpdateRow);
          return;
        }
      });
      // update row selected in form variable
      tempUpdateRow = form?.rows?.map((item, index) => {
        if (item.rowNumber === row.rowNumber) {
          return { ...tempUpdateRow[index], ...row };
        }
        return item;
      });
      setForm(prev => ({ ...prev, rows: tempUpdateRow }));
      startLoading();
      await updateTempContainer(tempUpdateRow, form?.shippingSessionId);
      stopLoading();
      if (dataExists) {
        msg = messageDisplay({
          type: MSG_TYPE.INFO,
          code: "MDN90044AINF",
          msg: [],
        });
        setMsgAlert([msg]);
      } else {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN95031AERR",
          msg: ["Shipping Plan", "Delete operation."],
        });
        setMsgError([msg]);
      }
      window.scrollTo(0, 0);
      setRowModesModel({});
      setRowSelectionModel([]);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonModeContainer({
      mode,
      rows,
      stateButton: { setAddBtn, setCopyBtn, setEditBtn, setDeleteBtn },
      modeOfWDN95030,
    });
    return;
  }, [mode, rows, modeOfWDN95030]);
  return (
    <React.Fragment>
      <InputButton
        id="button-copy-actual-data"
        value="Copy Actual Data"
        disabled={copyBtn}
        onClick={handleCopyActualData}
        name={`${functionId}Copy`}
      />
      <InputButton
        id="btn_add"
        value={BUTTON_VALUE.ADD}
        disabled={addBtn}
        onClick={handleAdd}
        name={`${functionId}Add`}
      />
      <InputButton
        id="btn_edit"
        value={BUTTON_VALUE.EDIT}
        disabled={editBtn}
        onClick={handleEdit}
        name={`${functionId}Edit`}
      />
      <InputButton
        id="button-container-delete"
        value={BUTTON_VALUE.DELETE}
        disabled={deleteBtn}
        onClick={handleDelete}
        name={`${functionId}Delete`}
      />
    </React.Fragment>
  );
}
