// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/counterSlice";
import authReducer from "shared/stores/reducers/authSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
  },
});

export default store;
